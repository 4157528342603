/* Orcamento.css */

.orcamento-container {
    background-color: #fff;
    color: #111;
    padding: 0px;
    border-radius: 8px;
    max-width: 700px;
    text-align: left;
  }
  
  .orcamento-container h2 {
    text-align: left;
    font-size: 24px;
  }
  
  .section {
    margin-top: 30px;
  }
  
  .section h4 {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .section ul {
    margin-top: 10px;
    padding-left: 20px;
    list-style: none;
  }
  
  .section ul li {
    margin-bottom: 8px;
    line-height: 1.5;
  }
  